<template>
  <!-- 合作企业管理管理 -->
  <div class="upstreamFirmPageBox">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    />
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button
          size="small"
          type="primary"
          icon="el-icon-plus"
          @click="addRow"
        >
          添加合作企业
        </el-button>
      </div>
      <Table
        :operation-button-width="236"
        :operation-button="operationButton"
        :item-data="itemData"
        :list-data="listData"
        :loading="loading"
      />
      <!-- 分页  -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
    <!-- 弹出的新增跟修改框 -->
    <Dialog
      ref="addAndEditDialog"
      dialog-width="35%"
      label-width="126px"
      :edit-form-data="editFormData"
      :form-item="addAndEditFormItem"
      @getFormData="getFormData"
    />
    <!-- 点击查看的弹窗 -->
    <el-dialog
      title="查看代采商"
      :visible.sync="checkForwarder"
      width="35%"
      :close-on-click-modal="false"
      @click="checkForwarder = false"
    >
      <h3 class="cooperativeEnterprise">
        合作企业：{{ forwarder.title }}
      </h3>
      <Table :item-data="forwarder.itemData" :list-data="forwarder.listData" />
      <!-- 分页  -->
      <Pagination
        :params="forwarder.formInline"
        :total="forwarder.total"
        :in-article="forwarder.listData.length"
        :get-data-list="getForwarderData"
      />
    </el-dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Dialog'
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { agentSupplierCmpAgentSupplierCmp, addAndEditAgentSupplierCmp, agentSupplierCmpCust } from '@/api/memberManagement'
import { getSelectAreaTree } from '@/api/login'
import { rules, formPattern } from '@/utils/matchFormRegex'

export default {
  components: { Dialog, FormSearch, Table, Pagination },
  data() {
    return {
      forwarder: {
        itemData: [
          { label: '代采商', prop: 'cmpName' },
          { label: '交收仓库', prop: 'deliveryInventoryName' },
          { label: '创建时间', prop: 'createTime' },
          { label: '合作状态', prop: 'cooStatus', child: [
            { value: '0', label: '无效' },
            { value: 1, label: '有效' }
          ] }
        ],
        title: '',
        listData: [],
        formInline: {
          pageSize: 10,
          pageNum: 1
        },
        total: 0
      },
      editFormData: {},
      checkForwarder: false,
      itemData: [
        { label: '合作企业名称', prop: 'partnerName', width: 180 },
        {
          label: '企业性质',
          prop: 'plantType',
          width: 80,
          child: this.$store.getters.getDictionaryItem('AgentSupplierInfoNature')
        },
        { label: '所在地区', prop: 'provinces', width: 100 },
        { label: '详细地址', prop: 'partnerAddr', width: 120 },
        { label: '联系人', prop: 'contacts', width: 180 },
        { label: '联系电话', prop: 'contactPhone', width: 180 },
        {
          label: '状态',
          prop: 'status',
          width: 80,
          child: [
            { value: 2, label: '无效' },
            { value: 1, label: '有效' }
          ]
        },
        { label: '创建时间', prop: 'createTime', width: 140 }
      ],
      addAndEditFormItem: [
        {
          type: 'input',
          label: '合作企业名称',
          value: 'partnerName',
          rules: [
            { required: true, message: '请输入合作企业名称', trigger: 'blur' },
            {
              min: 1,
              max: 25,
              message: '长度在 1 到 25 个字符',
              trigger: 'blur'
            }
          ]
        },
        {
          type: 'select',
          label: '企业性质',
          value: 'plantType',
          optionLabel: 'dictName',
          optionId: 'dictId',
          child: this.$store.getters.getDictionaryItem('AgentSupplierInfoNature')
        },
        {
          type: 'cascader',
          value: 'partner',
          label: '省市区',
          props: { label: 'name', value: 'name' },
          options: []
        },
        {
          type: 'input',
          label: '详细地址',
          value: 'partnerAddr',
          rules: [
            { required: true, message: '请输入详细地址', trigger: 'blur' },
            {
              min: 1,
              max: 30,
              message: '长度在 1 到 30 个字符',
              trigger: 'blur'
            }
          ]
        },
        {
          type: 'input',
          label: '联系人',
          value: 'contacts',
          rules: [
            { required: true, message: '请输入联系人', trigger: 'blur' },
            { ...formPattern.allChinese },
            {
              min: 1,
              max: 15,
              message: '长度在 1 到 15 个字符',
              trigger: 'blur'
            }
          ]
        },
        {
          type: 'input',
          label: '联系电话',
          value: 'contactPhone',
          rules: [...rules.contactInformation]
        },
        {
          type: 'radio',
          label: '是否有效',
          value: 'status',
          child: [
            { value: 1, label: '有效' },
            { value: 2, label: '无效' }
          ]
        }
      ],
      formItemArr: [
        { type: 'input', label: '合作企业', value: 'partnerName' }
        // { type: "input", label: "代采商", value: "deliveryInventoryName" }
      ],
      loading: false,
      total: 0,
      operationButton: [
        { bType: 'primary', label: '编辑', handleEvent: this.addRow },
        { bType: 'primary', label: '查看', handleEvent: this.viewDetails }
      ],
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1
      }
    }
  },
  mounted() {
    getSelectAreaTree('00', res => {
      this.addAndEditFormItem.map(item => {
        if (item.value === 'partner') {
          item.options = [...res.data]
        }
      })
    })
  },
  methods: {
    // 查看代采商的获取数据
    getForwarderData() {
      agentSupplierCmpCust(this.forwarder.formInline, res => {
        this.forwarder.listData = [...res.data.pageData]
        this.forwarder.total = res.data.total
      })
    },
    getFormData(value) {
      value.partnerProvince = value.partner[0]
      value.partnerCity = value.partner[1]
      value.partnerCounty = value.partner[2]
      addAndEditAgentSupplierCmp(value, () => {
        this.$message.success('成功！')
        this.$refs.addAndEditDialog.editFormVisible = false
        this.getdata()
      })
    },
    viewDetails(row) {
      this.forwarder.formInline.id = row.id
      this.forwarder.formInline.pageNum = 1
      agentSupplierCmpCust(this.forwarder.formInline, res => {
        this.checkForwarder = true
        this.forwarder.title = row.partnerName
        this.forwarder.listData = [...res.data.pageData]
        this.forwarder.total = res.data.total
      })
    },
    // 添加 or 编辑
    addRow(row) {
      this.$refs.addAndEditDialog.editFormVisible = true
      this.editFormData = row.constructor === Object ? { ...row, plantType: row.plantType + '', partner: [row.partnerProvince, row.partnerCity, row.partnerCounty] } : { status: 1 }
    },
    // 获取列表数据
    getdata(type) {
      if (!type) {
        this.formInline.pageNum = 1
      }
      agentSupplierCmpAgentSupplierCmp(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.provinces = item.partnerProvince + item.partnerCity + item.partnerCounty
        })
        this.total = res.data.total
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.upstreamFirmPageBox {
  .cooperativeEnterprise {
    font-size: 14px;
    margin-bottom: 12px;
    font-weight: 500;
    color: #333;
  }
}
</style>
